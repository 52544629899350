import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';
import search from "./Images/mynaui_search.svg";
import img1 from "./Images/heart.svg";
import img2 from "./Images/cart.svg";
import img3 from "./Images/user.svg";
import logo from "./Images/logo.png";
import "./Homepage.css"
const NavbarMain = () => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const handleNavbarToggle = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const handleOffcanvasClose = () => {
    setIsOffcanvasOpen(false);
  };

  return (
    <nav className="navbarmain shadow-lg">
      <div className="mx-auto px-5">
        <div className="flex justify-between items-center py-1">
          <div className="flex space-x-4">
            <div className="navbartext items-center space-x-1">
              <a href="#" className="py-2 px-2">Home</a>
              <a href="#" className="py-2 px-2">Shop</a>
              <a href="#" className="py-2 px-2">Product</a>
              <a href="#" className="py-2 px-2">Contact</a>
            </div>
          </div>
          <div className="flex justify-center">
            <a href="#" style={{color:"black",textDecration:"none"}} className="text-2xl font-bold"><img src={logo} style={{height:"70px"}}></img></a>
          </div>
          <div className="flex items-center space-x-4">
            <form className="searchitems">
              <input className="" type="search" placeholder="Search" aria-label="Search" />
              <button className="" type="submit">
                <img src={search} alt="" />
              </button>
              <a className='px-3 ps-5'><img src={img1} alt="img1" /></a>
              <a className='px-3'><img src={img2} alt="img2" /></a>
              <a className='px-3'><img src={img3} alt="img3" /></a>
            </form>
            <button className="md:hidden" onClick={handleNavbarToggle}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`fixed inset-0 z-50 md:hidden ${isOffcanvasOpen ? 'block' : 'hidden'}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', transition: 'opacity 0.3s ease-in-out' }}>
        <div className="fixed inset-y-0 left-0 bg-white w-64 p-4 transform" style={{ transition: 'transform 0.3s ease-in-out', transform: isOffcanvasOpen ? 'translateX(0)' : 'translateX(-100%)' }}>
          <div className="flex justify-between items-center mb-4">
            <h5 className="text-lg font-bold">Menu</h5>
            <button className="text-gray-600" onClick={handleOffcanvasClose}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <ul className="space-y-4">
            <li>
              <a href="#" className="block py-2 px-4 text-gray-700 hover:bg-gray-100 rounded">Home</a>
            </li>
            <li>
              <a href="#" className="block py-2 px-4 text-gray-700 hover:bg-gray-100 rounded">Shop</a>
            </li>
            <li>
              <a href="#" className="block py-2 px-4 text-gray-700 hover:bg-gray-100 rounded">Product</a>
            </li>
            <li>
              <a href="#" className="block py-2 px-4 text-gray-700 hover:bg-gray-100 rounded">Contact</a>
            </li>
            <li>
            <form className="searchitems">
              <input className="" type="search" placeholder="Search" aria-label="Search" />
              <button className="" type="submit">
                <img src={search} alt="" />
              </button>
              <a className='px-3 ps-5'><img src={img1} alt="img1" /></a>
              <a className='px-3'><img src={img2} alt="img2" /></a>
              <a className='px-3'><img src={img3} alt="img3" /></a>
            </form>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarMain;
