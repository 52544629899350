import React from 'react'
import { useSwiper } from 'swiper/react'
import nexticon from '../Components/Images/Button - Previous slide.svg'
import previcon from '../Components/Images/Button - Previous slide (1).svg';

import './Btn1.css'

export default function Btn1(){
    const swiper = useSwiper();
  return (
    <>
    <div className='Btn-group'>
    <button className='s-btn' onClick={()=>swiper.slidePrev()}><img src= {previcon}/></button>
    <button className='s-btn2' onClick={()=>swiper.slideNext()}><img src= {nexticon}/></button>

    </div>
    </>
  )
}
