import React from 'react';
import "./Homepage.css"
import 'tailwindcss/tailwind.css';
import shippinm4 from './Images/shippinm (4).png';
import shippinm3 from './Images/shippinm (3).png';
import shippinm2 from './Images/shippinm (2).png';
import shippinm1 from './Images/shippinm (1).png';
import ban3 from './Images/ban-3.png';
import ban4 from './Images/ban-4.png';
import neckles from './Images/neckles.png';
import likeIcon from './Images/like.png';
import product1 from './Images/product (1).png';
import product2 from './Images/product (2).png';
import product3 from './Images/product (3).png';
import product4 from './Images/product (4).png';
import product6 from './Images/product (6).png';
import product7 from './Images/product (7).png';
import product8 from './Images/product (8).png';
import product9 from './Images/product (9).png';
import testi from './Images/testi.png';

import Footer from './footer';

// import { Autoplay, Pagination, Na
import "swiper/css/pagination";
  // import 'react-lazy-load-image-component/src/effects/blur.css';
// -----

// testimonials image end
import "bootstrap/dist/css/bootstrap.min.css";

// import "./Homepage.css";

import { Swiper, SwiperSlide } from "swiper/react";



// Import Swiper styles
import { Parallax,Autoplay, Lazy, Navigation, Pagination } from "swiper/modules";
// STORE IMAGE END

import "swiper/css/navigation";
import "swiper/css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarMain from './navbarmain';
import Btn1 from '../Btn2/Btn1';

export default function HomePage(props) {
  return (<>
  <NavbarMain/>
    <div className='mainbackground'>
      <section>
        <div className="container pt-5">
          <div className="row ">
            <div className="col-md-9 col-12  firstban " style={{ position: 'relative' }}>
            <Swiper
        speed={600}
        parallax={true}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Parallax, Pagination, Navigation, Autoplay]}
        className="mySwiper2"
      >
        <div
          slot="container-start"
          className="parallax-bg"
         
          data-swiper-parallax="-23%"
        ></div>
        <SwiperSlide>
          <div className='slidecontent'>
          <div className="title" data-swiper-parallax="-300">
          Meet The Lines Of<br/>
          The Tillennium
          </div>
          <div className="subtitle" data-swiper-parallax="-200">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
          </div>
          <div className="text" data-swiper-parallax="-100">
           <button>Shop Now</button>
          </div></div>        </SwiperSlide>
          <SwiperSlide>
          <div className='slidecontent'>
          <div className="title" data-swiper-parallax="-300">
          Meet The Lines Of<br/>
          The Tillennium
          </div>
          <div className="subtitle" data-swiper-parallax="-200">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
          </div>
          <div className="text" data-swiper-parallax="-100">
           <button>Shop Now</button>
          </div></div>        </SwiperSlide>
                  <SwiperSlide>
          <div className='slidecontent'>
          <div className="title" data-swiper-parallax="-300">
          Meet The Lines Of<br/>
          The Tillennium
          </div>
          <div className="subtitle" data-swiper-parallax="-200">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
          </div>
          <div className="text" data-swiper-parallax="-100">
           <button>Shop Now</button>
          </div></div>        </SwiperSlide>
          <Btn1/>
      </Swiper>
            </div>
            <div className="col-md-3 mt-5 mt-md-0 col-12 secondban">
            
             <div className='productbannercontent'>
             <>
             <p>EAR Rings</p>
             <p>FLASH SALE</p>
             <p>25 NOV - 29 NOV</p>
             <a href='/products' style={{color:"white"}}>See More Products</a>
             </>
             </div>
             </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="shippingbox">
                <img src={shippinm4} alt="" />
                <b>Amazing Value Every Day</b>
                <span>Items prices that fit your budget</span>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="shippingbox">
                <img src={shippinm3} alt="" />
                <b>Amazing Value Every Day</b>
                <span>Items prices that fit your budget</span>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="shippingbox">
                <img src={shippinm2} alt="" />
                <b>Amazing Value Every Day</b>
                <span>Items prices that fit your budget</span>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="shippingbox">
                <img src={shippinm1} className="img-fluid" alt="" />
                <b>Amazing Value Every Day</b>
                <span>Items prices that fit your budget</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-3 col-12 mb-5 mb-md-0 productbanner2">
             <div className='productbannercontent'>
             <>
             <p>EAR Rings</p>
             <p>FLASH SALE</p>
             <p>25 NOV - 29 NOV</p>
             <a href='/products'>See More Products</a>
             </>
             </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product9} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product8} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product7} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product6} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product1} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product4} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product3} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product2} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="dicountbox mx-5 my-5">
        <p>Super discount for your first purchase</p>
        <p>first250</p>
        <p>Use discount code in checkout page.</p>
      </div>

      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-3 col-12 mb-md-0 mb-5 productbanner">
             <div className='productbannercontent'>
             <>
             <p>NECKLACES</p>
             <p>FLASH SALE</p>
             <p>25 NOV - 29 NOV</p>
             <a>See More Products</a>
             </>
             </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product9} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product8} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product7} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product6} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product1} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product4} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product3} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
                <div className="col-md-3 col-6 product" style={{ position: 'relative' }}>
                  <img src={likeIcon} className="likeicon" alt="" />
                  <img src={product2} className="img-fluid" alt="" />
                  <p>The Air Scoop-Neck Tee</p>
                  <span>₹1695.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="neck-ban">
          <div className="neck-bancontent container  ps-1 ps-md-5 ms-1 ms-md-5">
            <p>Necklaces</p>
            <p>Latest Bestsellers</p>
            <a href="">See More Products</a>
          </div>
        </div>
      </section>
    </div>

    
<section style={{backgroundColor: "#FAF8F0"}}><div className='testimonials container py-5'>
<h5>TESTIMONIALS</h5>
 
    <Swiper
      spaceBetween={30}
      // centeredSlides={true}
        style={{
          '--swiper-pagination-color': 'black',
        }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
     pagination={{
          clickable: true,
          el: '.swiper-pagination', // Ensures the pagination element is targeted
        }}
      loop={true}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
      breakpoints={{
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 4,
        },
      }}
    >
      <SwiperSlide><div className='testibox my-5'>
      
      <div className='row mb-2'>
      <div className='col-4'>
      <img src={testi} className='img-fluid rounded'></img>
      </div>
      <div className='col-8'><p>Jessica Brown</p>
      <span>Customer</span>
      
      </div>
      <div className='text-center mt-4'><p>This is due to their excellent service,
competitive pricing and customer support.
It’s throughly refresing to get such a
personal touch. Duis aute lorem ipsum is
simply free text irure dolor in reprehenderit
in esse nulla pariatur</p></div>
      </div>
      </div></SwiperSlide>
      <SwiperSlide><div className='testibox my-5'>
      
      <div className='row mb-2'>
      <div className='col-4'>
      <img src={testi} className='img-fluid rounded'></img>
      </div>
      <div className='col-8'><p>Jessica Brown</p>
      <span>Customer</span>
      
      </div>
      <div className='text-center mt-4'><p>This is due to their excellent service,
competitive pricing and customer support.
It’s throughly refresing to get such a
personal touch. Duis aute lorem ipsum is
simply free text irure dolor in reprehenderit
in esse nulla pariatur</p></div>
      </div>
      </div></SwiperSlide>
      <SwiperSlide><div className='testibox my-5'>
      
      <div className='row mb-2'>
      <div className='col-4'>
      <img src={testi} className='img-fluid rounded'></img>
      </div>
      <div className='col-8'><p>Jessica Brown</p>
      <span>Customer</span>
      
      </div>
      <div className='text-center mt-4'><p>This is due to their excellent service,
competitive pricing and customer support.
It’s throughly refresing to get such a
personal touch. Duis aute lorem ipsum is
simply free text irure dolor in reprehenderit
in esse nulla pariatur</p></div>
      </div>
      </div></SwiperSlide>
      <SwiperSlide><div className='testibox my-5'>
      
      <div className='row mb-2'>
      <div className='col-4'>
      <img src={testi} className='img-fluid rounded'></img>
      </div>
      <div className='col-8'><p>Jessica Brown</p>
      <span>Customer</span>
      
      </div>
      <div className='text-center mt-4'><p>This is due to their excellent service,
competitive pricing and customer support.
It’s throughly refresing to get such a
personal touch. Duis aute lorem ipsum is
simply free text irure dolor in reprehenderit
in esse nulla pariatur</p></div>
      </div>
      </div></SwiperSlide>
      <SwiperSlide><div className='testibox my-5'>
      
      <div className='row mb-2'>
      <div className='col-4'>
      <img src={testi} className='img-fluid rounded'></img>
      </div>
      <div className='col-8'><p>Jessica Brown</p>
      <span>Customer</span>
      
      </div>
      <div className='text-center mt-4'><p>This is due to their excellent service,
competitive pricing and customer support.
It’s throughly refresing to get such a
personal touch. Duis aute lorem ipsum is
simply free text irure dolor in reprehenderit
in esse nulla pariatur</p></div>
      </div>
      </div></SwiperSlide>      <SwiperSlide><div className='testibox my-5'>
      
      <div className='row mb-2'>
      <div className='col-4'>
      <img src={testi} className='img-fluid rounded'></img>
      </div>
      <div className='col-8'><p>Jessica Brown</p>
      <span>Customer</span>
      
      </div>
      <div className='text-center mt-4'><p>This is due to their excellent service,
competitive pricing and customer support.
It’s throughly refresing to get such a
personal touch. Duis aute lorem ipsum is
simply free text irure dolor in reprehenderit
in esse nulla pariatur</p></div>
      </div>
      </div></SwiperSlide>
       <div className="swiper-pagination"></div>
    </Swiper>


      </div>
      </section>

      <section>
      <div className='banner5'>
      <h6>Explore Our Product</h6>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br/> eiusmod tempor incididunt ut labore et dolore magna.</p>
      <button>View all products</button>
      </div>
      </section>
      <Footer/>
  </>);
};
