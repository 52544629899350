import React, { useState } from "react";
import './Homepage.css';
import "bootstrap/dist/css/bootstrap.min.css";
import social1 from './Images/social (1).png';
import social2 from './Images/social (2).png';
import social3 from './Images/social (3).png';
import social4 from './Images/social (4).png';
import social5 from './Images/social (5).png';

export default function(props){

  return (
    <>
<div className="mainfooter ">
   <div className="container mx-auto">
<div className="row">
<div className="col-md-3 col-6 footercontent"><ul>
  
  <li>About Company Name</li>
<li>company Inside</li>
<li>About Us </li>
<li>Company </li>
<li>Careers </li>
<li>Brands </li>

</ul></div>
<div className="col-md-2 col-6 footercontent"><ul>
  
  <li>Categories</li>
<li>Rings</li>
<li>Accessories </li>
<li>Earrings </li>
<li>Gold Buckle </li>
<li>Bracelets </li>

</ul></div>
<div className="col-md-3 col-12 footercontent"><ul>
  
  <li>New Collection Launch</li>
<li>Content</li>
<li>Seasonal Promotions </li>
<li>Customer Stories</li>
<li>Educational Series </li>
<li>Collaborations and Partnerships </li>

</ul></div>
<div className="col-md-4 col-12 contact text-start">
<from>
  <label>NEWSLETTER</label>
  <div className="d-flex"><input type="text" placeholder="Your Email"></input>
  <button>submit</button>
  </div>
<p>Sign up to get the latest on new Products, Promotions, Design news and more</p>
</from>
<div className="">
  <div className=" contact2"><p>Follow</p>
  <div className="d-flex justify-content-between items-center">
  <img src={social1}></img>
  <img src={social2}></img>
  <img src={social3}></img>
  <img src={social4}></img>
  <img src={social5}></img>
  <div className=" contact3 text-end">
  (+91) 98764310
  </div>
  </div>
    
  </div>

</div>
</div>
</div>
   </div>
   <div className="copyrights">
<p>© 2024 vilvanetworks All rights reserved.</p>
<p> Developed & Maintained By Vilva Business.</p>
   </div>
  </div>      

      
    </>
  );
};
